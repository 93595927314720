'use strict';

angular.module('casist.ubytovanie')
  .controller('UbytovanieOsobaDetailCtrl', ['$scope', '$modalInstance', '$controller', 'Profile', 'SearchCtrl', 'item', 'items', '$timeout', '$modal', '$q', 'DokladTypy', 'UbytovanieOsoba', 'Firma', 'Prevadzka', 'Dialog',
    function($scope, $modalInstance, $controller, Profile, SearchCtrl, item, items, $timeout, $modal, $q, DokladTypy, UbytovanieOsoba, Firma, Prevadzka, Dialog) {

      $scope.items = items;
      $scope.Firma = Firma;
      $scope.krajiny = UbytovanieOsoba.getKrajiny();
      $scope.pohlavia = [
        {id: 1, pohlavie: 'Muž'},
        {id: 2, pohlavie: 'Žena'}
      ];
      $scope.prevadzky = Prevadzka.restangularize(angular.copy(Profile.get('prevadzky')));

      $scope.RecordCtrl = $controller('RecordCtrl', {
        $scope: $scope,
        $modalInstance: $modalInstance,
        Model: UbytovanieOsoba
      });

      var defaultRecord = {
        pohlavie: 1,
        kod_krajiny: '1703'
      };

      $scope.RecordCtrl.main.init({
        model: 'record',
        master: item,
        list: items,
        defaultItem: defaultRecord,
        //postSave: firmaPostSave,
        searchCtrl: SearchCtrl,
        $modalInstance: $modalInstance
      });
      if (!isEmpty(item)) {
        $scope.record = UbytovanieOsoba.copy(item);
        _.defaults($scope.record, defaultRecord)
      } else {
        $scope.record = angular.copy(defaultRecord);
      }

      $scope.ok = function () {
        var promise = $scope.RecordCtrl.main.save();
        if (promise) {
          promise.then(function(result) {
            $modalInstance.close(result);
          });
        }
      };

      $scope.cancel = function () {
        $scope.RecordCtrl.main.dismiss();
      };

      $scope.registerFace = function (osoba) {
        UbytovanieOsoba.registerFace(osoba.id).then(
          function() {
            Dialog.alert(`Register tváre je aktivovaný pre osobu ${osoba.meno}.`, 'Úspech');
        }).catch(
          function (error) {
            Dialog.alert(error.data.detail, 'Chyba');
        });
    };

    }]);
