'use strict';

angular.module('casist.ubytovanie')
  .controller('UbytovanieOsobaCtrl', [
    '$scope',
    'Profile',
    '$controller',
    'TabService',
    'Karta',
    'Firma',
    'UbytovanieOsoba',
    'Notification',
    '$filter',
    function (
      $scope,
      Profile,
      $controller,
      TabService,
      Karta,
      Firma,
      UbytovanieOsoba,
      Notification,
      $filter
    ) {
    $scope.SearchCtrl = $controller('SearchCtrl');

    var defaultPeriod = {};

    $scope.UbytovanieOsoba = UbytovanieOsoba;
    $scope.Firma = Firma;
    $scope.Karta = Karta;

    $scope.osoby = [];
    $scope.otherFilters = {};
    var filterChanged = function(filter) {
      filter = angular.extend(filter, $scope.otherFilters);
      // $scope.jeFilterOsoba = angular.isDefined(filter.osoba);

      var promise = UbytovanieOsoba.objects().getList(filter);
      $scope.osoby.loading = true;
      promise.then(function(data) {
        TabService.registerScope('osoby', $scope);
        $scope.osoby = data;

        if ($scope.selectedAll) {
          for (var i = 0; i < $scope.osoby.length; i++) {
            $scope.osoby[i].selected = true;
          }
        } else {
          var osoba;
          _.every($scope.selectedItems, function (obj) {
            if ((osoba = _.find($scope.osoby, {id: obj.id}))) {
              $scope.selectedItems[obj.id] = osoba;
              $scope.selectedItems[obj.id].selected = true;
            }
          });
        }
      });
      return promise;
    };

    var loadPrevadzky = function() {
      $scope.prevadzky = _.filter(Profile.get('prevadzky'), {agenda: Profile.get('agenda').id});
      $scope.prevadzky.splice(0, 0, {'id': _.map(_.filter(Profile.get('prevadzky'), {agenda: Profile.get('agenda').id}), prevadzka => prevadzka.id).join(','), 'prevadzka': 'Všetky prevádzky'});
    };
    loadPrevadzky();

    $scope.osobyTable = [
      { width: 50 },
      { selectAllAddon: true },
      { field: 'id', sortField: 'meno', label: 'Meno', linked: { source: 'UbytovanieOsoba.get(query, pagination)', template: 'osoby-selection', lookup: 'meno', style: "{width: '500px'}", editable: false, paginateBy: 10 }},
      { field: 'pohlavie', sortField: 'pohlavie', label: 'Pohlavie' },
      { field: 'rodne_cislo', sortField: 'rodne_cislo', label: 'Rodné číslo' },
      { field: 'cop', sortField: 'cop', label: 'COP' },
      { field: 'biometric_card_id', label: 'Č. karty', },
      { field: 'pp_prevadzka', label: 'Prevádzka' },
      { field: 'izba', label: 'Aktívna izba', sortable: false },
      { field: 'firma', sortField: 'firma__firma', label: 'Firma', linked: { source: 'Firma.get(query)', template: 'linked-element-match-table', lookup: 'firma', style: "{width: '400px'}", editable: false } },
      { field: 'je_cudzinec', sortField: 'cudzinec', icon: '<i class="fa fa-globe"></i>', placeholder: 'Cudzinec', tooltip: 'Odídenec', filterCollapse: 'left'},
      { field: 'je_znizena_dan', sortField: 'znizena_dan', icon: '<i class="fa fa-hand-o-down"></i>', placeholder: 'Znížená daň', tooltip: 'Znížená daň', filterCollapse: 'left'},
      { field: 'je_dlhodobo', sortField: 'dlhodobo', icon: '<i class="fa fa-calendar-check-o"></i>', placeholder: 'Dlhodobo', tooltip: 'Dlhodobo', filterCollapse: 'left'},
      { field: 'je_fakturacia', sortField: 'fakturacia', icon: '<i class="fa fa-file-text-o"></i>', placeholder: 'Fakturácia', tooltip: 'Fakturácia', filterCollapse: 'left'},
      { field: 'je_active_in_biometric', sortField: 'je_active_in_biometric', icon: '<i class="fa fa-address-card"></i>', placeholder: 'Biometric', tooltip: 'Je aktívny v systéme Biomectric', filterCollapse: 'left'}
    ];

    $scope.SearchCtrl.init({
      onChange: filterChanged,
      columnsDef: $scope.osobyTable,
      defaultPeriod: defaultPeriod,
      defaultFilter: {
        'prevadzka': _.map(_.filter(Profile.get('prevadzky'), {agenda: Profile.get('agenda').id}), prevadzka => prevadzka.id).join(',')
      },
      // sort: {sort: 'iddokd', reverse: false},
      scope: $scope
    });
    var unregisterObservers = [];
    unregisterObservers.push(Profile.addObserver('obdobie', function(obdobie) {
      defaultPeriod = {};
      $scope.SearchCtrl.setDefaultPeriod(defaultPeriod);
      $scope.SearchCtrl.setLastPage();
      $scope.SearchCtrl.refresh();
    }));
    $scope.SearchCtrl.refresh(TabService.bus.get('osoby'));

    var events = UbytovanieOsoba.registerEvents(function() { return $scope.osoby; }, function() { return $scope.SearchCtrl; });
    $scope.$on('$destroy', function() {
      UbytovanieOsoba.unregisterEvents(events);
      for (var i in unregisterProfileObservers) {
        unregisterProfileObservers[i]();
      }
    });

    var unregisterProfileObservers = [];
    unregisterProfileObservers.push(Profile.addObserver('agenda', function() {
      $scope.SearchCtrl.cancelFilter(false);
      $scope.SearchCtrl.setLastPage();
      $scope.SearchCtrl.refresh();
    }));

    $scope.create = function(item) {
      return UbytovanieOsoba.edit(item, $scope.osoby, $scope.SearchCtrl);
    };
    $scope.copy = function(item) {
      var copied = UbytovanieOsoba.copy(item);
      _.each(['id'], function(field) {
        delete copied[field];
      });
      $scope.create(copied);
    };
    var updateOznacenych = function() {
      var selectedItemsCount = _.keys($scope.selectedItems).length;
      switch (selectedItemsCount) {
        case 0:
          $scope.selectedItemsText = '';
          break;
        case 1:
          $scope.selectedItemsText = 'Označený '+ selectedItemsCount +' záznam';
          break;
        case 2: case 3: case 4:
          $scope.selectedItemsText = 'Označené '+ selectedItemsCount +' záznamy';
          break;
        default:
          $scope.selectedItemsText = 'Označených '+ selectedItemsCount +' záznamov';
          break;
      }
    };

    $scope.selectedItems = {};
    $scope.selectedAll = false;
    $scope.selectAll = function() {
      var selectedAll = !$scope.selectedAll;
      $scope.selectedItems = {};
      for (var i = 0; i < $scope.osoby.length; i++) {
        $scope.osoby[i].selected = selectedAll;
        if (selectedAll) {
          $scope.selectedItems[$scope.osoby[i].id] = $scope.osoby[i];
        } else {
          delete $scope.selectedItems[$scope.osoby[i].id];
        }
      }
      updateOznacenych();
    };

    $scope.selectOsoba = function(osoba) {
      if ($scope.selectedAll) {
        $scope.selectedAll = false;
      }
      if ($scope.selectedItems[osoba.id]) {
        delete $scope.selectedItems[osoba.id];
      } else {
        $scope.selectedItems[osoba.id] = osoba;
      }
      updateOznacenych();
    };

    var _updateBulk = function (osoby) {
        UbytovanieOsoba.updateBulk(osoby)
          .then(
            function () {
              _.each(osoby, function (osoba) {
                delete osoby[osoba.id];
                _.find($scope.osoby, {id: parseInt(osoba.id)}).selected = false;
                UbytovanieOsoba.syncChange(osoba);
              });

              if ($scope.selectedAll) {
                $scope.selectedAll = false;
              }
              updateOznacenych();
              $scope.SearchCtrl.refresh();
              Notification.add('Zvolené záznamy ubytovania boli úspešne upravené.', 'success', 10);
            }

          ).catch(
            function (error) {
              if (error.status >= 400 && error.status < 500) {
                Notification.add('Záznamy ubtovania sa nepodarilo upraviť.', 'danger', 10);
              }
            }
          );
    };

     $scope.parseOsobaTooltip = function(item) {
      var content = '';
      if (item.tel) {
        content += '<br/>'+item.tel;
      }
      if (item.firma) {
        content += '<br/>'+item.firma_data.firma;
      }
      if (item.pp_od && item.pp_do) {
        content += '<br/><br/>PP: '+$filter('date')(item.pp_od, 'dd.MM.yyyy')+' - '+$filter('date')(item.pp_do, 'dd.MM.yyyy');
      } else if (item.pp_od) {
        content += '<br/><br/>PP od: '+$filter('date')(item.pp_od, 'dd.MM.yyyy');
      } else if (item.pp_do) {
        content += '<br/><br/>PP do: '+$filter('date')(item.pp_do, 'dd.MM.yyyy');
      }
      content = content.replace(new RegExp('^<br\/>'), '');
      return content;
    };

  }]);
