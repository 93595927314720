var defaultTranslations = {
  "main": {
    "RELOAD": "Obnoviť",
    "UZIVATELIA": "Používatelia",
    "SKUPINY": "Skupiny",
    "ODHLASENIE": "Odhlásenie",
    "FAKTURACIA": "Fakturácia",
    "POHLADAVKY": "Pohľadávky",
    "VYDANE_FAKTURY": "Vydané faktúry",
    "ZAVAZKY": "Záväzky",
    "SKLAD": "Sklad",
    "UCTOVNICTVO": "Účtovníctvo",
    "SVB": "SVB",
    "ZMENIT_UDAJE": "Zmeniť údaje",
    "ZMENIT_DODAVATELA": "Zmeniť dodávateľa",
    "ZMENIT_PREVADZKU": "Zmeniť prevádzku",
    "DETAIL": "Detail",
    "COPY": "Kopírovať",
    "PRINT": "Tlač",
    "ULOZITPDF": "Uložiť PDF",
    "POSLATMAILOM": "Poslať e-mailom",
    "JAZYK_PROGRAMU": "Jazyk",
    "NASTAVENIA_PROFILU": "Nastavenia profilu",
    "GLOBALNE_NASTAVENIA": "Globálne nastavenia",
    "OBDOBIE": "Obdobie",
    "OBDOBIA": "Obdobia",
    "NEWTAB": "Nová záložka",
    "VALUE_EXACT": "Presná hodnota",
    "VALUE_LESS": "Hodnota menšia alebo rovná ako",
    "VALUE_MORE": "Hodnota väčšiaa alebo rovná ako",
    "VALUE_RANGE": "Hodnota od - do",
    "FROM": "od",
    "TO": "do",
    "DATE": "dátum",
    "MONTH": "mesiac",
    "YEAR": "rok",
    "ZOBRAZENIE": "Zobrazenie",
    "NOVA": "Nová",
    "NOVY": "Nový",
    "PRIDAT": "Pridať",
    "ZRUSIT": "Zrušiť",
    "VYMAZAT_DOKLAD": "Vymazať doklad",
    "ZRUSIT_ZMENY": "Zrušiť zmeny",
    "ULOZIT_ZMENY": "Uložiť zmeny",
    "SPAT": "Späť",
    "ZAKLADNE": "Základné",
    "OSTATNE": "Ostatné",
    "ZRUSIT_FILTER": "Zrušiť filtre",
    "UNIVERSAL_FILTER": "Univerzálny filter '/'",
    "NEXT": "Ďalšia",
    "PREVIOUS": "Predošlá",
    "FIRST": "Prvá",
    "LAST": "Posledná",
    "SAVE": "Uložiť",
    "CLOSE": "Zatvoriť bez uloženia",
    "ONLY_CLOSE": "Zatvoriť",
    "UPRAVIT": "Upraviť",
    "IBA_AKTIVNE": "Iba aktívne",
    "IBA_AKTIVNYCH": "Iba aktívnych",
    "ODOBRAT": "Odobrať",
    "PRIRADIT": "Priradiť",
    "ZIADNE": "Žiadne",
    "ZIADNE_UDAJE": "Žiadne údaje.",
    "VYBRANE": "Priradené",
    "POUZITE": "Použité",
    "DOSTUPNE": "Dostupné",
    "PRAVA": "Práva",
    "VYMAZAT": "Vymazať",
    "LOADING_DATA": "Údaje sa načítavajú...",
    "VERZIE_ZAZNAMU": "História záznamu",
    "DELETE_FROM_LIST": "Vymazať z uloženého zoznamu",
    "PREHLADY": "Prehľady",
    "HLAVNA_KNIHA": "Hlavná kniha",
    "PRIMARNA_MENA": "Primárna mena",
    "PRIMARNA_MENA_SKRATKA": "Mena skrátene",
    "LOCKED_RECORD": "Uzamknutý záznam",
    "MOZNOSTI": "Možnosti",
    "PRIDAT_ODSTRANIT": "Pridať alebo odstrániť",
    "DALSICHZAZNAMOV": "ďalších záznamov",
    "DALSIZAZNAM": "ďalší záznam",
    "DALSIEZAZNAMY": "ďalšie záznamy",
    "BANKOVE_DOKLADY": "Bankové doklady",
    "POKLADNICNE_DOKLADY": "Pokladničné doklady",
    "PENAZNY_DENNIK": "Peňažný denník",
    "INTERNE_DOKLADY": "Interné doklady",
    "PRIKAZY": "Príkazy",
    "URGENCIE": "Urgencie",
    "CENNIKY": "Cenníky",
    "POZICIE": "Pozície",
    "OSOBY": "Osoby",
    "DATUM": "Dátum",
    "HLAVICKA_ZAZNAMU": "Hlavička",
    "ROZPIS": "Rozpis",
    "MAILBOX": "Mailová schránka",
    "JEDNODUCHE_UCTO": "Jednoduché účtovníctvo",
    "POCET_ZAZNAMOV_NA_STRANU": "Záznamov na stranu",
    "VYTLAC_ID_KLIENT": "Vytlač id klienta",
    "IMAGE": "Obrázok",
    "ATTACHMENT": "Príloha",
    "EXPORT": "Export",
    "PREVODKA": "Prevodka",
    "ZMENA_SKLADU": "Zmena skladu",
  },
  "fakturacia": {
    "FAKTURA_CISLO": "Faktúra č.",
    "NOVA_FAKTURA": "Nová faktúra",
    "VS": "VS",
    "ODBERATEL": "Odberateľ",
    "DODAVATEL": "Dodávateľ",
    "VYSTAVENIE": "Vystavenie",
    "DODANIE": "Dodanie",
    "UCTOVANIE": "Účtovanie",
    "UPLAT_DPH": "Uplat. DPH",
    "SPLATNOST": "Splatnosť",
    "SDPH1": "% DPH 1",
    "SDPH2": "% DPH 2",
    "OBJEDNAVKA": "Objednávka",
    "DOPRAVA": "Doprava",
    "TYPDPH": "Typ DPH",
    "PREDAJCA": "Predajca",
    "ZLAVAP": "% Zľava",
    "MARZAP": "% Marža",
    "KURZ": "Kurz",
    "MENA": "Mena",
    "POZNAMKA": "Poznámka",
    "HLAVICKA": "Hlavička",
    "PATA": "Päta",
    "KONECNY_PRIJEMCA": "Konečný príjemca",
    "SPOLU_ZM": "Spolu ZM",
    "SPOLU": "Spolu",
    "PREDKONTACIA": "Predkontácia",
    "ZAKLAD_D1": "Základ DPH 1",
    "ZAKLAD_D2": "Základ DPH 2",
    "ZAKLAD_0": "Základ 0",
    "SUMA1": "Suma 1",
    "SUMA2": "Suma 2",
    "SUMA3": "Suma 3",
    "SUMA4": "Suma 4",
    "SUMA5": "Suma 5",
    "SUMA6": "Suma 6",
    "SUMA7": "Suma 7",
    "SUMA8": "Suma 8",
    "SUMA9": "Suma 9",
    "SUMA10": "Suma 10",
    "SUMA11": "Suma 11",
    "DPH1": "DPH1",
    "DPH2": "DPH2",
    "ROZDIELMDD": "Rozdiel MD - D",
    "POPIS": "Popis",
    "KUHRADE": "K úhrade",
    "POVODNA_FAKTURA": "Číslo OF",
    "CISLO_OPRAVNEJ_FAKTURY": "Číslo opravnej faktúry",
    "ZLAVA_SUMA": "Suma zľavy",
    "NAKLADY": "Náklady",
    "SKLAD": "Sklad",
    "PRIJEMKA": "Príjemka",
    "POCET_BALIKOV": "Počet balíkov"
  },
  "pohyb": {
    "POHYB": "Prehľad pohybu",
    "KOD": "Kód",
    "NAZOV": "Názov",
    "MNOZSTVO": "Množstvo",
    "MNOZSTVO_P": "Množstvo príjem",
    "MNOZSTVO_V": "Množstvo výdaj",
    "CENA_P": "Cena príjem",
    "CENA_V": "Cena výdaj",
    "CENAMJ": "Cena/MJ",
    "MJ": "MJ",
    "DPH": "DPH",
    "PRIDAT_POLOZKU": "Pridať položku",
    "SKLAD": "Sklad",
    "CENA": "Cena",
    "CENA_PREDAJ": "Cena predaj",
    "POZNAMKA": "Poznámka"
  },
  "zavazky": {
    "NOVY_ZAVAZOK": "Nový záväzok",
    "ZAVAZOK_CISLO": "Záväzok č.",
    "PORADOVE_CISLO": "Por. číslo.",
    "TYP": "Typ"
  },
  "pokladna": {
    "POKLADNA": "Pokladňa",
    "AKTIVNA": "Aktívna",
    "POKLADNE": "Pokladne",
    "VSETKY_POKLADNE": "všetky pokladne",
    "KP": "KP",
    "TYP_POKLADNE": "Typ pokladne",
    "KOD_POKLADNE": "Kód pokladne",
    "UHRADENE": "Uhradené",
    "UHRADENIE": "Uhradenie",
    "UHRADA": "Pár. doklad",
    "DOKLAD_CISLO": "Pokladničný doklad č.",
    "NOVY_DOKLAD": "Nový pokladničný doklad",
    "VYPLATENE_KOMU": "Vyplatené komu",
    "PRIJATE_OD": "Prijaté od",
    "PRIJMOVY_DOKLAD": "Príjem",
    "VYDAVKOVY_DOKLAD": "Výdaj",
    "NAZOV": "Názov",
    "CISLO_PRIJEM": "Číslo pre príjem",
    "CISLO_VYDAJ": "Číslo pre výdaj",
    "DEFAULT_OPERACIA": "Operácia",
    "OPERACIA": "+/-",
    "HOTOVOST": "Hotovosť",
    "KARTA": "Karta",
    "BLOK": "Blok"
  },
  "interne": {
    "NOVY_DOKLAD": "Nový interný doklad",
    "DOKLAD_CISLO": "Interný doklad č."
  },
  "prevadzky": {
    "PREVADZKA": "Prevádzka",
    "NOVA_PREVADZKA": "Nová prevádzka",
    "KOD": "Kód",
    "POUZIVATELIA": "Používatelia v prevádzke",
    "DEVICES": "Zariadenia",
    "DEPARTMENT": "Oddelenie",
    "ACCESS_GROUPS": "Prís. skupina",
    "FACE_REGISTER_CODE": "Register tváre",
  },
  "agendy": {
    "AGENDA": "Agenda",
    "VSETKY_AGENDY": "všetky agendy",
    "AGENDY_POUZIVATELA": "Agendy používateľa",
    "PRIDAT_PRAVA": "Pridať práva pre agendu",
    "POUZIVATELIA": "Používatelia v agende",
    "PREVADZKY": "Prevádzky v agende",
    "PRAVA": "Práva pre túto agendu",
    "ZRUSIT_PRAVA": "Zrušiť práva pre túto agendu",
    "VYBERTE": "Vyberte agendu",
    "NOVA_AGENDA": "Nová agenda",
    "FIRMA": "Firma",
    "ICO": "IČO",
    "DIC": "DIČ",
    "ICDPH": "IČ DPH",
    "AKTIVNA": "Aktívna",
    "ULICA": "Ulica",
    "PSC": "PSČ",
    "ADRESA": "Adresa",
    "CISLO": "Číslo",
    "MESTO": "Mesto",
    "DANOVY_URAD": "Daňový úrad",
    "EAN_DODAVATEL": "EAN Dodávateľ",
    "EMAIL": "Email",
  },
  "settings": {
    "VSEOBECNE": "Všeobecné",
    "AGENDY": "Agendy",
    "PREVADZKY": "Prevádzky",
    "PLATBY": "Platby",
    "UDAJE_O_PREVADZKE": "Údaje o prevádzke",
    "VSEOBECNE_UDAJE": "Všeobecné údaje",
    "VSETKY_PREVADZKY": "Všetky prevádzky",
    "VYBRANE_PREVADZKY": "Len vybrané prevádzky",
    "POKLADNE": "Pokladne",
    "VSETKY_POKLADNE": "Všetky pokladne",
    "VYBRANE_POKLADNE": "Len vybrané pokladne",
    "DECIMAL_PLACES_INVOICE": "Počet desatinných miest na faktúre",
    "DEFAULT_HEADER": "Predvolená hlavička",
    "DEFAULT_FOOTER": "Predvolená päta",
    "DPH": "DPH",
    "FAKTURACIA": "Fakturácia",
    "TLAC": "Tlač",
    "PRIRADENE_MODULY": "Priradené moduly",
    "DOSTUPNE_MODULY": "Dostupné moduly",
    "SKUPINY": "Skupiny",
    "ADD": "Vytváranie záznamov",
    "CHANGE": "Upravovanie záznamov",
    "DELETE": "Mazanie záznamov",
    "PRINT": "Tlač záznamov",
    "EXPORT": "Export záznamov",
    "MODULY": "Moduly",
    "NASTAVENIA_ULOZENE": "Nastavenia boli uložené.",
    "NASTAVENIA_NEULOZENE": "Nastavenia sa nepodarilo uložiť.",
    "ERROR_ODOBRATIE_AGENDY": "Odobratie agendy sa nepodarilo.",
    "ERROR_PRIDANIE_AGENDY": "Pridanie agendy sa nepodarilo",
    "UZIVATEL_ULOZENY": "Používateľ bol uložený.",
    "UZIVATEL_NEULOZENY": "Nastala chyba pri vytváraní používateľa.",
    "ERROR_UZIVATEL_DELETE": "Nepodarilo sa vymazať používateľa",
    "UZIVATEL_REAKTIVACIA_OK": "Používateľovi bol znova zaslaný aktivačný e-mail.",
    "UZIVATEL_REAKTIVACIA_ERROR": "Nepodarilo sa odoslať aktivačný e-mail.",
    "SKUPINA_ULOZENA": "Skupina bola uložená.",
    "SKUPINA_NEULOZENA": "Nastala chyba pri ukladaní skupiny.",
    "SKUPINA_VYTVORENA": "Skupina bol vytvorená.",
    "SKUPINA_NEVYTVORENA": "Nastala chyba pri vytváraní skupiny.",
    "SKUPINA_NEVYMAZANA": "Nepodarilo sa vymazať skupinu",
    "SKUPINA_VYMAZAT": "Naozaj chcete vymazať skupinu ",
    "DUE_DAYS_INVOICE": "Predvolený počet dní splatnosti",
    "TLACIT_KODY": "Tlačiť kódy tovarov",
    "TLACIT_UCTY": "Tlačiť bankové účty",
    "TLACIT_CZ": "Faktúry CZ",
    "UCTY": "Bankové účty",
    "TEXTFA": "Text na faktúre",
    "PRINT_SETTINGS": "Zmena údajov pre tlač",
    "TEXTVYUCTOVANIE": "Text na vyúčtovaní",
    "EMAIL": "Email",
    "EMAIL_PREDMET": "Predmet",
    "EMAIL_TELO": "Telo",
    "EMAIL_CC": "CC"
  },
  "users": {
    "FIRST_NAME": "Meno",
    "LAST_NAME": "Priezvisko",
    "PASSWORD_CHANGE": "Zmena hesla",
    "OLD_PASSWORD": "Pôvodné heslo",
    "EMAIL": "E-mail",
    "PASSWORD": "Heslo",
    "PASSWORD_CHECK": "Kontrola hesla",
    "INFORMATION": "Informácie",
    "USERNAME": "E-mail",
    "ACTIVE": "Aktívny",
    "LAST_LOGIN": "Posl. prihlásenie",
    "PRAVA_PRE": "Práva pre",
    "CAKA_AKTIVACIA": "Čaká na aktiváciu<br><br>Kliknutím znova odošlete aktivačný e-mail",
    "NIE_JE_AKTIVOVANY": "Používateľ zatiaľ nie je aktivovaný",
    "PRAVA_SUPERUSER": "Administrátor"
  },
  "groups": {
    "SKUPINA": "Skupina",
    "AGENDA": "Agenda"
  },
  "obdobia": {
    "MESIAC": "Mesiac",
    "ROK": "Rok",
    "UZAMKNUTE": "Uzamknuté"
  },
  "ucto": {
    "MD": "MD",
    "D": "D",
    "UCTOVANIE_MD": "MD",
    "UCTOVANIE_D": "D",
    "DD": "DD",
    "RECORD": "doklad",
    "VS": "VS",
    "KS": "KS",
    "SS": "SS",
    "ISSUED": "vystavenie",
    "SUPPLIED": "dodanie",
    "DUE": "splatnosť",
    "FIRMA_ICO": "Firma / IČO",
    "SPOLU": "spolu",
    "UHRADENE": "uhradené",
    "ROZDIEL": "rozdiel",
    "ZAKLAD1": "základ D1",
    "ZAKLAD2": "základ D2",
    "ZAKLAD0": "základ 0",
    "SPOLUZM": "spolu ZM",
    "KURZ": "kurz",
    "MENA": "mena",
    "SD1": "% D1",
    "SD2": "% D2",
    "SD1_FULL": "Sadzba DPH 1",
    "SD2_FULL": "Sadzba DPH 2",
    "OBJEDNAVKA": "objednávka",
    "DOPRAVA": "doprava",
    "PREDAJCA": "predajca",
    "DPH1": "DPH1",
    "DPH2": "DPH2",
    "MDPH": "MDPH",
    "TDPH": "TDPH",
    "ZLAVA": "% Zľava",
    "NEUHRADENE": "Neuhradené",
    "UHRADA": "Úhrada",
    "NEZAUCTOVANE": "Nezaúčtované",
    "ZAHRANICNE": "Zahraničné",
    "NESPAROVANE": "Nespárované",
    "VSETKY_PREVADZKY": "všetky prevádzky",
    "UCTOVANIE": "Účtovanie",
    "ROZUCTOVANIE": "Rozúčtovanie",
    "UCTOVNY_DOKLAD": "Účtovný doklad",
    "POLOZKY_FAKTURY": "Položky faktúry",
    "POLOZKY": "Položky",
    "SUMAMD": "Suma MD",
    "SUMAD": "Suma D",
    "ROZUCTOVANY_ZAZNAM": "Rozúčtovaný záznam",
    "PORADOVE_CISLO": "Por.č.FA",
    "SPOLU_ZAVAZKY": "Spolu DF",
    "ROZDIEL_ZAVAZKY": "Rozdiel P-DF",
    "NAKLADY": "Náklady",
  },
  "errors": {
    "WRONG_DATE": "Nesprávny dátum",
    "FIRMA": "Firma musí byť zadaná a uložená v zozname",
    "TYP": "Typ musí byť zadaný a uložený v zozname"
  },
  "firmy": {
    "ADRESAR_FIRIEM": "Adresár firiem",
    "SPLATNOST": "Splat.",
    "ZLAVA": "% Zľava",
    "UCET": "Účet",
    "FIRMA": "Firma",
    "NOVA_FIRMA": "Nová firma",
    "TELEFON": "Telefón",
    "EMAIL": "E-mail",
    "EMAILS": "E-maily",
    "ULICA": "Ulica",
    "MESTO": "Mesto",
    "FAKTURACNE_UDAJE": "Fakturačné údaje",
    "KONTAKTNE_UDAJE": "Kontaktné údaje",
    "OSTATNE_UDAJE": "Ostatné údaje",
    "KONTAKTNE_OSOBY": "Kontaktné osoby",
    "PREVADZKY": "Prevádzky",
    "MENO": "Meno",
    "MOBIL": "Mobil",
    "FAX": "Fax",
    "WEB": "Web",
    "POZNAMKA": "Poznámka",
    "ADRESAT": "Adresát",
    "CENNIKY": "Cenníky",
    "CENNIK": "Cenník",
    "EAN_PARTNER": "EAN partner",
    "EAN_ODBERATEL": "EAN odberateľ",
    "EAN_MIESTO_DODANIA": "EAN miesto dodania",
    "TLAC_EAN1": "Tlačiť EAN1 kód",
    "CENA_UBYTOVANIE": "Cena ubytovanie",
    "KOD_KRAJINY": "Kód krajiny",
  },
  "predkontacie": {
    "PREDKONTACIA": "Predkontácia",
    "PREDKONTACIE": "Predkontácie",
    "NOVA_PREDKONTACIA": "Nová predkontácia",
    "NAZOV": "Názov",
    "POPIS": "Popis",
    "TYP": "Typ",
    "VSETKY_TYPY": "všetky typy",
    "PREDKONTACIA_PD": "Úhrada pokladňa",
    "PREDKONTACIA_DF": "Predkontácia DF",
    "PREDKONTACIA_BD": "Úhrada banka",
    "PREDKONTACIA_VF": "Predkontácia VF"
  },
  "ucty": {
    "UCET": "Účet",
    "NOVY_UCET": "Nový účet",
    "NAZOV": "Názov",
    "UCET_NAZOV": "Účet / Názov",
    "RSA": "Riadok súvaha A",
    "RSP": "Riadok súvaha P",
    "RV": "Riadok výsledovka",
    "RDP": "Riadok DP",
    "RJU": "Riadok výkazu",
    "RSA_SHORT": "RS A",
    "RSP_SHORT": "RS P",
    "RV_SHORT": "RV",
    "RDP_SHORT": "RDP",
    "RJU_SHORT": "RV",
  },
  "uctosnova": {
    "UCTOSNOVA": "Účtovná osnova",
    "PSMD": "Počiatočný stav MD",
    "PSD": "Počiatočný stav D",
    "PSMD_SHORT": "Poč. stav MD",
    "PSD_SHORT": "Poč. stav D",
    "PS": "Počiatočný stav ku 1.1.{{rok}}",
    "KSMD": "Konečný stav MD",
    "KSD": "Konečný stav D",
    "KSMD_SHORT": "Kon. stav MD",
    "KSD_SHORT": "Kon. stav D",
    "KS": "Konečný stav",
    "KSROZDIEL": "Konečný stav MD-D",
    "KUMULATIVMD": "Kumulatívny obrat MD",
    "KUMULATIVD": "Kumulatívný obrat D",
    "KUMULATIV": "Kumulatívný obrat",
    "OBRAT_MD": "Obrat za {{mesiac}} MD",
    "OBRAT_D": "Obrat za {{mesiac}} D",
    "OBRAT": "Obrat za {{mesiac}}"
  },
  "uctdennik": {
    "SUMAD": "Suma D",
    "UCET": "Účet",
    "UCTDENNIK": "Účtovný denník",
    "NENULOVE_ROZDIELY": "Nenulové rozdiely"
  },
  "mesiace": {
    "JANUAR": "Január",
    "FEBRUAR": "Február",
    "MAREC": "Marec",
    "APRIL": "Apríl",
    "MAJ": "Máj",
    "JUN": "Jún",
    "JUL": "Júl",
    "AUGUST": "August",
    "SEPTEMBER": "September",
    "OKTOBER": "Október",
    "NOVEMBER": "November",
    "DECEMBER": "December",
  },
  "banka_ucty": {
    "IBAN": "IBAN",
    "BIC": "BIC",
    "KU": "KÚ",
    "KOD_UCTU": "Kód účtu",
    "AKTIVNY": "Aktívny",
    "DEFINICIA_UCTOV": "Definícia účtov"
  },
  "banka": {
    "NOVY_DOKLAD": "Nový bankový doklad",
    "DOKLAD_CISLO": "Bankový doklad č.",
    "VYPIS_CISLO": "Výpis č.",
    "VYPIS": "Výpis"
  },
  "rozuctovanie": {
    "NOVY_SPOSOB": "Nový spôsob rozúčtovania",
    "SPOSOB": "Spôsob",
    "ROZUCTOVANIE": "Rozúčtovanie",
    "NOVA_POLOZKA": "Nová položka",
    "POLOZKA": "Položka",
    "NAZOV": "Názov",
    "NAKLADY": "Náklady",
    "VYNOSY": "Výnosy",
    "ZISK": "Zisk",
    "SUMA": "Suma",
    "SUMARNE_POLOZKA": "Sumárne za položku",
    "MEDZISUMY_POLOZKA": "Medzisumy za položku"
  },
  "supisdph": {
    "SUPISDPH": "Súpis dokladov DPH",
    "VSTUPVYSTUP": "Vstup aj výstup",
    "VSETKY_TYPY": "všetky typy DPH"
  },
  "cislovanieDokladov": {
    "CISLOVANIE_DOKLADOV": "Číslovanie dokladov",
    "CISLOVANIE_VS": "Číslovanie variabilných symbolov",
    "DD": "Druh dokladu",
    "FIXNA_CAST": "Fixná časť",
    "VARIABILNA_CAST": "Variabilná časť",
    "VYSLEDNE_CISLO": "Výsledné číslo dokladov",
    "VYSLEDNY_VS": "Výsledný variabilný symbol"
  },
  "urgencie": {
    "SUMA": "Suma",
    "TEXT": "Text",
    "TEXT_FULL": "Predvolený text urgencií",
    "NOVA_URGENCIA": "Nová urgencia",
    "URGENCIA": "Urgencia",
    "URGENCIA_CISLO": "Urgencia č.",
    "HLAVICKA": "Hlavička"
  },
  "prikazy": {
    "PRIKAZ": "Príkaz",
    "NOVY_PRIKAZ": "Nový príkaz",
    "DAVKA": "Dávka",
    "ULOZIT_DAVKU": "Uložiť dávku",
    "DEBET_UCET": "Debetný účet",
    "KREDIT_UCET": "Kreditný účet"
  },
  "mailbox": {
    "DATE": "Dátum",
    "TO": "Adresa",
    "SUBJECT": "Predmet",
    "RELATED_RECORD": "Prepojený záznam",
    "SMS": "SMS"
  },
  "penaznydennik": {
    "NOVY_POKLADNA": "Pokladňa",
    "NOVY_BANKA": "Banka",
    "RIADOK": "Riadok",
    "DANOVE": "Daňové",
    "NEDANOVE": "Nedaňové",
    "PP": "PP",
    "KUKP": "FÚ",
    "KUKP_FULL": "Forma úhrady",
    "UCEL": "Účel",
    "ROZDIELMDD": "Rozdiel"
  },
  "karty": {
    "KARTY": "Karty",
    "KOD": "Kód",
    "KOD_EAN": "EAN kód",
    "NAZOV": "Názov",
    "TYP": "Typ",
    "SPOSOB_ROZUCTOVANIA": "Rozúčtovanie",
    "MO": "MO Cena",
    "VO": "VO Cena",
    "MODPH": "MO s DPH",
    "CENA_PRIEMER": "Cena/MJ",
    "CENA_DODANE": "Dodané M/J",
    "CENA_NAMERANE": "Namerané M/J",
    "CENA_SKLAD": "Cena sklad",
    "CENY": "Ceny",
    "POPIS": "Popis",
    "CENNIKY": "Cenníky",
    "MNOZSTVA": "Množstvá",
    "ZAKLADNE_UDAJE": "Základné údaje",
    "KARTA": "Karta",
    "NOVA_KARTA": "Nová karta",
    "DPH": "% DPH",
    "MJ": "MJ",
    "SUMACIA": "Sumácia",
    "TYPY": "Typy kariet",
    "SKLAD": "Sklad",
    "SKUPINA": "Skupina",
    "PODSKUPINA": "Podskupina",
    "DRUH": "Druh",
    "DODAVATEL": "Dodávateľ",
    "VYROBCA": "Výrobca",
    "BALENIE": "Balenie",
    "OBJEM": "Objem",
    "HMOTNOST": "Hmotnosť",
    "MINMNOZSTVO": "Min. množstvo",
    "ZARUKA": "Záruka",
    "NAZOV_CZ": "Názov CZ",
    "VAHA_NETTO": "Váha netto",
    "VAHA_BRUTTO": "Váha brutto",
    "VHODNE_OD": "Vhodné od",
    "ZAHRANICNA_CENA": "Zahraničná cena",
    "VELKOST_BALENIA": "Veľkosť balenia",
    "VELKOST_PRODUKTU": "Veľkosť produktu",
    "SYNC_TO_ESHOP": "Sync e-shop",
    "ESHOP_LABEL": "E-shop label",
    "MASTER_VARIANT": "Hlavná karta",
    "VARIANT_OPTION_TYPE": "Typ variantov",
    "VARIANT_OPTION_NAME": "Názov variantu",
    "VARIANT_OPTION_VALUE": "Hodnota variantu",
    "RECYKLACNY_POPLATOK": "Recyklačný poplatok",
    "MO_CZ": "MO CZ bez DPH",
    "MODPH_CZ": "MO CZ s DPH",
    "MMO": "%MMO",
    "MVO": "%MVO",
    "KOD_DODAVATELA": "Kód dodávateľa",
    "TARIC": "Taric",
    "KOD_EAN1": "EAN1 kód",
    "POZICIE": "Pozície v sklade",
    "LIMIT_MNOZSTVO": "Lim. množstvo",
  },
  "cenniky": {
    "NAZOV": "Názov",
    "NAZOV_KARTY": "Názov karty",
    "NAZOV_KARTY_ALT": "Alt. názov karty",
    "MO": "C cena",
    "POZNAMKA": "Poznámka",
    "MCC": "%MCC",
    "MO_KARTA": "MOCena",
    "MMO": "%MMO",
    "VO_KARTA": "VOCena",
    "MVO": "%MVO",
    "MO_CZ_KARTA": "MO CZ",
    "MODPH_CZ_KARTA": "MO CZ s DPH",
    "CENA_SKLAD_KARTA": "Cena sklad",
    "KARTA": "Karta",
  },
  "pozicie": {
    "ZAKLADNE_UDAJE": "Základné údaje",
    "NAZOV_KARTY": "Názov karty",
    "KOD_KARTY": "Kód karty",
    "EAN_KARTY": "EAN karty",
    "NAZOV_SKLADU": "Názov skladu",
    "KOD_SKLADU": "Kód skladu",
    "POZICIA": "Pozícia",
    "POZNAMKA": "Poznámka",
  },
  "ponuky": {
    "PONUKY": "Odbyt",
    "PONUKA_CISLO": "Záznam číslo",
    "NOVA_PONUKA": "Nová ponuka",
    "NOVY_ZAZNAM": "Nový záznam",
    "VYTVORIT_DF": "Vytvoriť DF",
    "ZOBRAZIT_FAKTURU": "Zobraziť faktúru",
    "PAROVANIE": "Párovanie",
    "UCEL": "Účel"
  },
  "nakup": {
    "NAKUP": "Nákup",
    "DODACI_LIST": "Dodací list",
    "CISLO_FA": "Číslo FA"
  },
  "zalohove": {
    "ZALOHOVEFAKTURY": "Zálohové faktúry",
    "ZALOHOVA_FAKTURA_CISLO": "Zálohová faktúra číslo",
    "NOVA_ZALOHOVA_FAKTURA": "Nová zálohová faktúra",
    "VYFAKTUROVAT": "Vyfakturovať zálohovú faktúru",
    "ZOBRAZIT_FAKTURU": "Zobraziť faktúru"
  },
  "sklad": {
    "PREPOCET_SKLADU": "Prepočet skladu",
    "SKLADY": "Sklady",
    "SKLAD": "Sklad",
    "KOD": "Kód",
    "NAZOV": "Názov",
    "PREVADZKA": "Prevádzka",
  },
  "mnozstva": {
    "MNOZSTVA": "Množstvá",
    "SPOLU": "Spolu"
  },
  "svb": {
    "PRIESTORY": "Priestory",
    "VLASTNICI": "Vlastníci",
    "VYUCTOVANIE": "Vyúčtovanie",
    "ODPOCTY_MERACOV": "Odpočty meračov",
    "PREHLAD_NAKLADOV": "Prehľad nákladov a spotreby"
  },
  "priestory": {
    "PRIESTOR": "Priestor",
    "NOVY_PRIESTOR": "Nový priestor",
    "NAZOV": "Názov",
    "DOM": "Dom",
    "VCHOD": "Vchod",
    "POSCHODIE": "Poschodie",
    "POZNAMKA": "Poznámka",
    "PLOCHA_BYT": "Byt",
    "PLOCHA_CELKOM": "Celkom",
    "PLOCHA_BALKON": "Balkón",
    "PLOCHA_PIVNICA": "Pivnica",
    "VYKUR_PLOCHA": "Plocha",
    "VYSKA": "Výška",
    "VYKUR_OBJEM": "Objem",
    "PODIEL": "Podiel"
  },
  "vlastnici": {
    "NOVY_VLASTNIK": "Nový vlastník",
    "VLASTNIK": "Vlastník",
    "MENO": "Meno",
    "ZASTUPCA": "Zástupca",
    "VS": "VS",
    "DATUM_OD": "Dátum od",
    "DATUM_DO": "Dátum do",
    "POZNAMKA": "Poznámka",
    "POCET_OSOB": "Počet osôb",
    "VLASTNICI": "Vlastníci",
    "ADRESAT": "Adresát",
    "Email": "Email"
  },
  "merace": {
    "NAZOV": "Názov",
    "UMIESTNENIE": "Umiestnenie",
    "SN": "SN",
    "DRUH": "Druh",
    "DATUM_MONTAZE": "Dátum montáže",
    "DATUM_DEMONTAZE": "Dátum demontáže",
    "PLATNOST_DO": "Platnosť do"
  },
  "sluzby": {
    "SLUZBA": "Služba",
    "KOD": "Kód",
    "NAZOV": "Názov služby",
    "ROZUCTOVANIE": "Rozúčtovanie",
    "MJ": "MJ",
    "OBJEKT": "Objekt",
    "PRIESTOR": "Priestor",
    "ZALOHY": "Zálohy",
    "ROZDIEL": "Rozdiel",
    "MJOBJEKT": "MJ/Objekt",
    "MJPRIESTOR": "MJ/Priestor",
    "ZALOHA": "Záloha",
    "PREPOCITANA_ZALOHA": "Prepočítaná záloha",
    "PREPOCITANA": "Prepočítaná"
  },
  "odpocty": {
    "DATUM": "Dátum",
    "STAV": "Stav",
    "PREDCHADZAJUCI_STAV": "Predchádzajúci stav",
    "PRED_STAV": "Pred. stav",
    "SPOTREBA": "Spotreba",
    "SPOSOB": "Spôsob",
    "KOEFICIENT": "Koeficient",
    "POZNAMKA": "Poznámka"
  },
  "ubytovanie": {
    "UBYTOVANIE": "Ubytovanie",
    "IZBY": "Izby",
    "OSOBY": "Osoby",
  }
};
export default defaultTranslations;
